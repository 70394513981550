<template>
	<div id="adoptList">
		<page-adopt-list is-shop="0"></page-adopt-list>
	</div>
</template>

<script>
import PageAdoptList from '@/components/layout/adopt/page-adopt-list.vue'
import { onBeforeRouteLeave } from 'vue-router'
import store from '@/store'
export default{
	name:"AdoptList",
	components:{
		PageAdoptList
	},
	setup(){
		onBeforeRouteLeave((to,from,next)=>{
			let type = to.path == '/adopt/edit' ? 1 : 2
			store.dispatch("setKeepPage",{name:from.name,type})
			next();
		})
	}
}
</script>

<style lang="scss">
	.adopt-search-input{
		width: 350px!important;
	}
	.kd-adoptlist{
		width: 100%;
		display: flex;
  	
		&-cover{
			width: 50px;
			height: 50px;
			border-radius: 5px;
			margin-right: 10px;
		}
	}
</style>
